jQuery(document).ready(function($) {
    
    function navToggle() {
		$('.header__nav-button').toggleClass('header__nav-button--active');
		$nav.toggleClass('nav--active');
    }

	$('.header__nav-button').click(function() {
		navToggle();
	});

	$('li', $nav).click(function() {
		navToggle();
	})
 
});
