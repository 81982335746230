jQuery(document).ready(function($) {

	var frameCount = 22;
		frameTime = 100;

	function birdBackgroundPosition() {
		var backgroundChange = 173;
			currentBackgroundPosition = -3806;
			count = 0;

		storeTimeInterval = setInterval(function() {
		  	currentBackgroundPosition = currentBackgroundPosition + backgroundChange;
		  	++count;

		  	$('.logo-animation__bird').css('background-position', currentBackgroundPosition + 'px 0');

		  	if(count >= frameCount) {
		  		clearInterval(storeTimeInterval);
		  	}

		}, frameTime);
	}

	function birdPosition() {
		var transition = frameCount * frameTime + 'ms';
			transitionAfter = (frameCount * frameTime) + 1000 + 'ms';

		// Set delay on logo
		$('.logo-animation__logo').css('transition-delay', transition);

		// Set delay on other content
		$('.logo-after-animation').each(function() {
			$(this).css('transition-delay', transitionAfter);
		});

		// Begin animation
		$('.logo-animation').addClass('logo-animation--active');

		$('.logo-animation__bird').css({
			'transition': 'left ' + transition + ', top ' + transition
		});

		// Set after animation classes.
		$('.logo-after-animation').each(function() {
			$(this).addClass('logo-after-animation--active');
		});

	}
    
	birdBackgroundPosition();
	birdPosition();
 
});
