jQuery(document).ready(function($) {
    
	$('.btn--chevron').click(function(e) {
		var $parent = $(this).parents('section');
			$next = $parent.next('section');

		$('html, body').animate({
            scrollTop: $next.offset().top - 70
        }, 1500);
	});
 
});
